<script setup lang="ts">
    import Dialog from 'primevue/dialog';
    import LoginSignup from '@/components/login/LoginSignup.vue';

    defineProps<{
        isVisible: boolean;
    }>();

    const emits = defineEmits<{
        (event: 'loginSuccess'): void;
        (event: 'forgotPassword'): void;
        (event: 'signup'): void;
        (event: 'cancelLogin'): void;
    }>();

    function loginSuccess(): void {
        emits('loginSuccess');
    }

    function forgotPassword(): void {
        emits('forgotPassword');
    }

    function signup(): void {
        emits('signup');
    }
</script>

<template>
    <Dialog
        :visible="isVisible"
        modal
        :block-scroll="true"
        header=" "
        class="max-width-container login-dialog"
        :pt="{
            content: { style: 'padding: 0;' },
            closeButton: { onClick: () => emits('cancelLogin') },
        }"
    >
        <LoginSignup
            :basket-login="true"
            @login-success="loginSuccess"
            @forgot-password="forgotPassword"
            @signup="signup"
        />
    </Dialog>
</template>

<style scoped lang="scss"></style>
